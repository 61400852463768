.navbar-container {
    display: flex;
    height: 80px;
    align-items: center;
    width: 100%;
    position: absolute;
    z-index: 10;
    box-shadow: 0px -1px 2px 0px #ffffff;
    top: 0;
}

.navbar-container.bg {
    background-color: var(--primary2-color);
}

.navbar-items.start {
    flex: 1;
    display: flex;
    justify-content: center;
    text-decoration: none;
    align-items: center;
}

.navbar-items.start h1 {
    color: var(--secondary-color);
    font-size: 22px;
    padding-left: 5px;
}

.navbar-items.start img {
    width: 65px;
}

.navbar-items.start h1 span {
    color: var(--primary-color);
}

.navbar-items.mid {
    flex: 3;
    display: flex;
    justify-content: center;
    height: 100%;
}

.list-main,
.navbar-items.mid span {
    text-decoration: none;
    color: var(--secondary-color);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 13.5px;
    transition: 0.3s;
    cursor: pointer;
}

.navbar-items.mid .list-main:hover {
    color: var(--primary-color);
}

.list-main {
    height: 100%;
    display: flex;
    align-items: center;
}

.navbar-item {
    padding: 0px 15px;
    list-style: none;
    height: 100%;
}

.navbar-items.end {
    flex: 1;
    display: flex;
    justify-content: center;
}

.navbar-items.end a {
    background-color: var(--primary-color);
    padding: 10px 15px;
    border: none;
    color: white;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    text-decoration: none;
}

.navbar-items.end a:hover {
    background-color: var(--primary2-color);
}

.tooltip-container {
    position: absolute;
    background-color: var(--primary-color);
    z-index: 1;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 120px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.tooltip-container .first-subcat {
    list-style: none;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.submain-one {
    width: 100%;
    text-align: center;
    padding: 10px 0px;
    transition: 0.3s;
}

.submain-one:hover {
    background-color: white;
    border-right: 3px solid var(--primary-color);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.submain-one:hover .submain-one-title {
    color: var(--primary2-color);
}



.subcat-container {
    position: absolute;
    left: 100%;
    top: 0;
    background-color: #f7f6f6;
    width: 320px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: flex-start;
    align-items: center;
    cursor: pointer;
    padding: 10px 0px;
    border-bottom: 3px solid var(--primary-color);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.subcat-container ul {
    list-style: none;
    width: 100%;
}

.subcat-container ul li {
    padding: 10px 0px;
    width: 100%;
    transition: 0.3s;
}

.subcat-container ul li a {
    color: var(--secondary2-color);
    text-decoration: none;
    font-weight: 600;
}

.verticle-line {
    position: absolute;
    width: 0.11em;
    height: 80%;
    margin: auto;
    background-color: var(--primary-color);
    border-radius: 25px;
    opacity: 0.8;
    left: 50%;
}

.subcat-container ul li:hover {
    background-color: white;
}

.subcat-container ul li:hover a {
    color: var(--primary2-color);
}

.mobile-navbar {
    display: none;
}

@media(max-width: 800px) {
    .navbar-items.mid {
        flex: 0;
    }

    .navbar-items.start h1 {
        color: var(--primary-color);
    }

    .navbar-items.start h1 span {
        color: var(--primary2-color);
    }

    .mobile-navbar {
        display: block;
        margin-top: 3px;
    }

    .mobile-navbar i {
        background-color: var(--primary-color);
        padding: 5px 7px;
        border-radius: 50%;
        font-size: 12px;
        color: white;
    }

    .navbar-container {
        flex-direction: column;
        height: 100vh;
        align-items: flex-start;
        background-color: #f9fff5;
        width: 300px;
        box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.3);
        position: fixed;
        left: -350px;
        transition: left 0.3s ease;
        z-index: 10000;
    }

    .navbar-container.open {
        left: 0;
    }

    .mobile-navbar {
        position: absolute;
        padding: 25px;
    }

    .navbar-items.start {
        width: 100%;
        text-align: center;
        padding: 25px;
    }

    .navbar-items.mid {
        flex-direction: column;
        margin: 25px;
    }

    .navbar-items.start {
        flex: 0;
    }

    .navbar-items.end {
        flex: 0;
        margin: 25px;
        position: absolute;
        bottom: 80px;
    }

    .navbar-item {
        padding: 20px 0px;
    }

    .navbar-items.mid a,
    .navbar-items.mid span {
        color: var(--secondary2-color);
    }

}


@media(max-width: 600px) {
    .navbar-container {
        width: 100vw;
        left: -101vw;
    }

    .navbar-container.open {
        left: 0;
    }

    .mobile-navbar i {
        background-color: var(--primary-color);
        padding: 10px 12px;
        border-radius: 50%;
        font-size: 18px;
        color: white;
    }

    .mobile-navbar.close i {

        padding: 8px 12px;
        border-radius: 50%;
        font-size: 18px;
        color: white;
    }
}