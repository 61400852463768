.follow-us-section {
    padding-top: 20px;
}

.socialIcon {
    position: relative;
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 10px 0;
    flex-wrap: wrap;
}

.socialIcon li {
    list-style: none;
}

.socialIcon li a {
    font-size: 2em;
    color: var(--secondary2-color);
    margin: 0 10px;
    display: inline-block;
    transition: 0.5s;
}

.socialIcon li a:hover {
    transform: translateY(-10px);
    color: var(--primary-color);
}




.footer-column {
    flex: 1;
}

.footer-column.span {
    flex: 2;
}


.footer-column h1 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.footer-column ul {
    list-style: none;
    padding: 0;
}

.footer-column ul li {
    margin-bottom: 5px;
}

.footer-column ul li i {
    margin-right: 10px;
}

.footer-column ul li a {
    text-decoration: none;
    color: var(--secondary2-color);
    font-weight: 500;
    transition: 0.3s;
}

.footer-column ul li a:hover {
    color: var(--secondary2-color);
}

.footer-copyright {
    text-align: center;
    color: var(--secondary-color);
    font-size: 0.9rem;
    width: 100%;
    background-color: var(--primary-color);
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}

.footer-column ul li:hover {
    color: var(--primary-color);
}

.footer-column ul li a:hover {
    color: var(--primary-color);
}

.footer-column h1::after,
.footer-column-content h1::after {
    content: "";
    display: block;
    width: 50px;
    height: 3px;
    background-color: var(--primary-color);
    border-radius: 50px;
    margin-top: 5px;
}



.footer-column-content h1 span {
    color: var(--primary-color);
}

.footer-container {
    padding-top: 50px;
    background-color: #f5faff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.footer-columns {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 30px;
}

.footer-column-content {
    flex: 2;
    max-width: 600px;
    /* Set your desired maximum width */
    padding: 0 10px 0 30px;
    /* Add some horizontal padding */
}

.footer-column-content p {
    padding-top: 15px;
    font-weight: 400;
    line-height: 1.3em;
}

@media(max-width:700px) {
    .footer-columns {
        flex-direction: column;
    }

    .footer-column {
        margin-left: 30px;
    }

    .follow-us-section {
        padding-top: 30px;
    }

    .footer-copyright p {
        font-size: 11px;
    }
}

.footer-column.span ul li {
    padding: 5px 0px;
    cursor: pointer;
}