.home-container {
    height: 100%;
    max-width: 100vw;
    overflow: hidden;
}

.home-main-section {
    height: 80vh;
    width: 100%;
}

.home-main-container {
    display: flex;
    align-items: center;
    height: 100%;
    width: 90%;
    margin: auto;
}

.home-main-player {
    flex: 1;
}

.home-title {
    flex: 1;
}

.home-title h1 {
    font-size: 62px;
    line-height: 1.1em;
    color: var(--secondary-color);
}

.home-title h1 span {
    color: var(--primary-color);
}

.home-title p {
    padding-top: 15px;
    color: var(--secondary-color);
    font-size: 18px;
    font-weight: 600
}

.home-background-wave {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.6)), url("./../../Asserts/home-new.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    bottom: 0;
    z-index: -1;
}

@media(max-width: 650px) {
    .home-main-player {
        display: none;
    }

    .home-title {
        position: relative;
        z-index: 1;
    }



    .home-title h1 {
        font-size: 48px;
    }

}


/*********about-us*******/
.about-us-section {
    background-color: white;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;

}

.about-us-container {
    width: 70%;
    margin: auto;

}

.about-us-title p {
    color: var(--primary2-color);
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 800;
}

.about-us-title h1 {
    font-size: 64px;
    padding-bottom: 10px;
    font-weight: 600;
    color: var(--primary-color);
}

.about-us-content p {
    color: var(--secondary2-color);
    font-weight: 500;
    padding-bottom: 50px;
    font-size: 18px;
}

.about-us-content a {
    text-decoration: none;
    color: white;
    background-color: var(--primary-color);
    padding: 15px 20px;
    border-radius: 25px;
    font-weight: 800;
    font-size: 16px;
    border: 2px solid var(--primary-color);
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    align-items: center;
    width: fit-content;
}

.about-us-content a i {
    padding-left: 5px;
}

.about-us-container a:hover {
    background-color: var(--primary2-color);
    border: 2px solid var(--primary2-color);
}


/*************services********/

.services-section {
    height: 100%;
    display: flex;
    align-items: center;
    background-color: #ececec;
    padding-bottom: 100px;
}

.services-container {
    width: 90%;
    margin: auto;
}

.services-title {
    text-align: center;
    padding-top: 100px;
}

.services-title p {
    color: var(--primary2-color);
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 800;
}

.services-title h1 {
    font-size: 54px;
    padding-bottom: 50px;
    font-weight: 600;
    color: var(--primary-color);
}

.services-content {
    padding-bottom: 100px;
    display: flex;
    justify-content: space-between;
}

.services-card-wrapper {
    width: 23%;
}

/*************productslider*********/

.recent-products-section {
    height: 100%;
    display: flex;
    align-items: center;
}

.recent-products-container {
    width: 90%;
    margin: auto;
    padding: 100px 0px 50px 0px;
}

.recent-product-title {
    padding-bottom: 50px;
}

.recent-product-title h1 {
    color: var(--primary-color);
    text-align: left;
}

.get-in-touch-section {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 100px;
}

.get-in-touch-container {
    width: 80%;
    height: 85%;
    background-color: var(--primary2-color);
    margin: auto;
    border-radius: 50px;
    display: flex;
    align-items: center;
    box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.5);
}

.get-in-touch-inner-container {
    width: 70%;
    margin: auto;
    padding-left: 50px;
}

.career-player {
    width: 30%;
    margin: auto;
    padding-right: 50px;
}

.get-in-touch-inner-container h1 {
    padding-bottom: 10px;
    font-size: 48px;
    font-weight: 600;
    color: var(--secondary-color);
}

.get-in-touch-inner-container p {
    padding-bottom: 50px;
    color: var(--secondary-color);
    font-size: 18px;
    font-weight: 600;
}

.get-in-touch-inner-container a {
    background-color: var(--primary-color);
    padding: 15px 20px;
    border-radius: 25px;
    color: white;
    font-weight: 800;
    transition: 0.3s;
    cursor: pointer;
    border: 2px solid var(--primary-color);
    font-size: 16px;
    text-decoration: none;
}

.get-in-touch-inner-container a i {
    padding-left: 5px;
}

.get-in-touch-inner-container a:hover {
    background-color: var(--primary2-color);
    border: 2px solid white;
}


.home-footer-wrapper {
    padding-top: 100px;
}

@media(max-width: 650px) {
    .about-us-section {
        background-color: white;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 60px;
        padding-bottom: 100px;

    }

    .about-us-title p {
        font-size: 14px;
    }

    .about-us-title h1 {
        font-size: 38px;
    }

    .about-us-content p {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.5rem;
        text-align: justify;
    }

    .about-us-content a {
        font-size: 14px;
        font-weight: 400;
        padding: 10px 15px;
    }

    .services-title p {
        font-size: 14px;
    }

    .services-title h1 {
        font-size: 38px;
    }


    .get-in-touch-section {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding-top: 100px;
    }

    .get-in-touch-container {
        width: 80%;
        height: 100%;
        background-color: var(--primary2-color);
        margin: auto;
        border-radius: 50px;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.5);
        padding-bottom: 80px;
    }

    .get-in-touch-inner-container {
        width: 100%;
        margin: auto;
    }

    .career-player {
        width: 100%;
        margin: auto;
        padding-right: 0px;
    }

    .get-in-touch-inner-container h1 {
        font-size: 32px;
    }

    .get-in-touch-inner-container p {
        padding-bottom: 50px;
    }

    .get-in-touch-inner-container a {

        padding: 10px 15px;
        font-size: 14px;
    }


}