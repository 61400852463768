.about-us-km-container {
    width: 100%;
}

.aboutus-heading {
    display: flex;
    width: 100%;
    height: 50vh;
    background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.6)), url("./../../Asserts/abt-banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    align-items: center;
    justify-content: center;
}

.aboutus-heading h1 {
    font-size: 64px;
    font-weight: 600;
    color: var(--secondary-color);
}

.aboutus-heading h1 span {
    color: var(--primary-color);
}

.about-us-kn-section {
    padding: 100px 0px 50px 0px;
    background-color: #f3f3f3;
    margin-bottom: 50px;
}

.about-us-kn-section h1 {
    text-align: left;
    width: 80%;
    margin: auto;
    font-size: 48px;
    font-weight: 600;
    padding-bottom: 25px;
    color: var(--primary-color);
    border-left: 5px solid var(--primary2-color);
    padding-left: 25px;
    margin-bottom: 15px;
}

.about-us-kn-section h1 span {
    color: var(--primary2-color);
}


.about-us-kw-content-container {
    display: flex;
    align-items: center;
    width: 80%;
    margin: auto;
}

.about-us-content-km {
    width: 60%;
}

.about-us-content-km p {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.3em;
    color: var(--secondary2-color);
    padding: 10px 0px;
    text-align: justify;
}

.about-us-player-km {
    width: 40%;
}

.vision-section {
    width: 80%;
    margin: auto;
    padding-bottom: 50px;
}

.vision-container {
    display: flex;
    height: 200px;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    border-radius: 25px;
}

.vision-title {
    width: 250px;
    background-color: var(--primary-color);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    position: relative;
}

.vision-title::before {
    content: "";
    position: absolute;
    top: 0;
    right: -10px;
    bottom: 0;
    width: 25px;
    background-color: white;
    transform: skewX(-7deg);
}

.vision-title h1 {
    color: white;
    font-size: 48px;
    padding: 25px;
    font-weight: 600;
}

.vision-content {
    flex: 3;
    margin-left: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    background-color: var(--primary2-color);
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.vision-content::before {
    content: "";
    position: absolute;
    top: 0;
    left: -30px;
    bottom: 0;
    width: 45px;
    background-color: var(--primary2-color);
    transform: skewX(-7deg);
}

.vision-content p {
    padding: 50px;
    color: white;
    font-size: 18px;
    font-weight: 500;
    text-align: right;
}

/**********mision--start---***********/

.mision-section {
    width: 80%;
    margin: auto;
    padding-bottom: 100px;
}

.mision-container {
    display: flex;
    height: 200px;
    align-items: center;
    flex-direction: row-reverse;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    border-radius: 25px;
}

.mision-title {
    width: 250px;
    background-color: var(--primary-color);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    position: relative;
}

.mision-title::before {
    content: "";
    position: absolute;
    top: 0;
    left: -10px;
    bottom: 0;
    width: 25px;
    background-color: white;
    transform: skewX(-7deg);
}

.mision-title h1 {
    color: white;
    font-size: 48px;
    padding: 25px;
    text-align: right;
    font-weight: 600;
}

.mision-content {
    flex: 3;
    margin-right: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    background-color: var(--primary2-color);
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.mision-content::before {
    content: "";
    position: absolute;
    top: 0;
    right: -30px;
    bottom: 0;
    width: 45px;
    background-color: var(--primary2-color);
    transform: skewX(-7deg);
}

.mision-content p {
    padding: 50px;
    color: white;
    font-size: 18px;
    font-weight: 500;
}

@media(max-width: 650px) {



    .about-us-kn-section h1 {

        font-size: 38px;
        font-weight: 600;
        padding-bottom: 0px;
        color: var(--primary-color);
        border-left: 3px solid var(--primary2-color);
    }

    .about-us-kw-content-container {
        flex-direction: column-reverse;
        width: 100%;
    }


    .about-us-content-km p {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.3em;
        color: var(--secondary2-color);
        padding: 10px 0px;
        text-align: justify;
    }

    .about-us-player-km {
        width: 100%;
    }


}


@media(max-width: 950px) {
    .about-the-company {
        width: 95%;
    }

    .company-features-container {
        flex-wrap: wrap;
        justify-content: center;
    }

    .vision-content p {
        font-size: 14px;
        padding: 5px;
    }

    .vision-title {
        width: 150px;
    }

    .vision-title h1 {
        font-size: 20px;
    }

    .mision-title {
        width: 150px;
    }

    .mision-title h1 {
        font-size: 20px;
    }

    .mision-content p {
        font-size: 14px;
        padding: 5px;
    }

}

@media(max-width: 650px) {

    .about-the-company {
        width: 95%;
        flex-direction: column;
    }

    .vision-container,
    .mision-container {
        flex-direction: column;
        height: fit-content;
    }

    .vision-title,
    .mision-title {
        width: 100%;
        border-radius: 5px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    .vision-title h1,
    .mision-title h1 {
        margin-bottom: 0px;
    }

    .mision-title::before,
    .vision-title::before {
        display: none;
    }

    .mision-content,
    .vision-content {
        width: 100%;
        margin: auto;
        border-radius: 0px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .mision-content::before,
    .vision-content::before {
        display: none;
    }

    .mision-content p,
    .vision-content p {
        width: 100% !important;
        padding: 15px;
        text-align: left;
    }


}