.carousel {
    position: relative;
    width: calc(min(90rem, 90%));
    margin: 0 auto;
    min-height: 100%;
    column-gap: 3rem;
}

@media screen and (min-width: 48rem) {
    .carousel {
        display: flex;
        align-items: center;
    }
}


.slider-btn {
    display: inline-block;
    color: #717171;
    background: #fff;
    padding: 0.625rem 1.875rem;
    margin-top: 2.125rem;
    text-decoration: none;
    text-transform: uppercase;
    border: 2px solid #c2c2c2;
    border-radius: 3.125rem;
    transition: 0.3s ease-in-out;
}

.slider-btn:hover {
    border: 0.125rem solid #00a4aa;
    color: #00a4aa;
}

.bgdonut1 {
    position: fixed;
    top: 0;
    left: -8rem;
    z-index: -1;
    opacity: 0.2;
}

.bgdonut2 {
    position: fixed;
    bottom: -2rem;
    right: -3rem;
    z-index: -1;
    width: 9.375rem;
    opacity: 0.2;
}

.myswiper {
    width: 100%;
    padding-top: 3.125rem;
}

.myswiper-slider {
    width: 18.75rem;
    height: 28.125rem;
    background-size: cover;
    background-position: center;
}

.myswiper-slider div {
    display: none;
    opacity: 0;
    padding-bottom: 0.625rem;
}

.swiper-slide-active div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
}

.swiper-slide-active div a {
    align-self: flex-start;
}

.myswiper-slider h2 {
    color: #fff;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.4;
    margin-bottom: 0.625rem;
    padding: 0 0 0 1.563rem;
    text-transform: uppercase;
}

.myswiper-slider p {
    color: #dadada;
    padding: 0 1.563rem;
    line-height: 1.6;
    font-size: 0.75rem;
}

.myswiper-slider a {
    border: 1px solid white;
    margin: 1.25rem 1.5rem 3.5rem;
    background-color: #fc0f72;
    color: white;
}

.myswiper-slider a:hover {
    color: #00a4aa;
}

.swiper-pagination-bullet,
.swiper-pagination-bullet-active {
    background: #fff;
}

.carousel-img {
    position: absolute;
    width: 100vw;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

.carousel-content {
    position: relative;
    z-index: 100;
    width: 80%;
}

.carousel-content h1 {
    font-size: 3rem;
    color: var(--primary2-color);
    padding-bottom: 20px;
}

.carousel-content p {
    font-size: 1.2rem;
    line-height: 1.7rem;
    font-weight: 400;
}

.carousel-content ul {
    margin-left: 10px;
    margin-top: 10px;

}

.carousel-content ul li {
    padding: 3px 0px;
    font-size: 1.2rem;
}

.carousel-content a {
    background-color: var(--primary-color);
    padding: 15px 20px;
    border-radius: 25px;
    color: white;
    font-weight: 800;
    transition: 0.3s;
    cursor: pointer;
    border: 2px solid var(--primary-color);
    font-size: 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-top: 30px;
    width: fit-content;
}

.carousel-content a i {
    padding-left: 5px;
}

.carousel-content a:hover {
    background-color: var(--primary2-color);
    border: 2px solid white;
}

@media(max-width: 650px) {
    .carousel-content {
        width: 100%;
        text-align: justify;
    }

    .carousel-content p {
        font-size: 18px;
        font-weight: 400;
    }

    .carousel-content a {
        font-size: 14px;
        font-weight: 400;
        padding: 10px 15px;
    }

}