.contact-us-main-section {
    width: 100%;
    height: 100%;
}

.contact-us-main-container {
    width: 100%;
    height: 100%;
}

.contact-us-main-title {
    width: 100%;
    height: 60vh;
    background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.6)), url("./../../Asserts/contact-br.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
}


.contact-us-main-title h1 {
    font-size: 64px;
    font-weight: 600;
    color: var(--secondary-color);
}

.contact-us-main-title h1 span {
    color: var(--primary-color);
}

.contact-us-main-content-container-wrapper {
    background-color: #f3f3f3;
    width: 100%;
    height: 100%;
}

.contact-us-main-content-container {
    width: 90%;
    margin: auto;
    height: 100%;
    padding: 100px 0px;
    display: flex;
    align-items: center;
}

.contact-us-main-content-container {
    flex: 1.5;
}



.cu-info-container h1 {
    color: var(--primary-color);
    padding-bottom: 25px;
    font-size: 32px;
    font-weight: 600;
    width: 70%;
    line-height: 1.2em;
}

.cu-info-item {
    width: 60%;
    padding-bottom: 25px;
}

.cu-info-item h2 {
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 800;
    color: var(--primary2-color);
}

.cu-info-item p {
    font-size: 15px;
    font-weight: 500;
    color: var(--secondary2-color);
}

.cu-form-container {
    width: 40%;
    background-color: white;
    height: 100%;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 50px;
    padding-top: 40px;
}

.cu-form-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0px 0px;
    height: 60px;
}

.cu-form-item.text {
    height: 100px;
}


.cu-form-item label {
    font-size: 16px;
    height: 100%;
    color: #8e8e8e;
    padding: 0px 10px;
    border-bottom: 1px solid #8e8e8e;
    height: 100%;
}

.cu-form-item label i {
    height: 100%;
    display: flex;
    align-items: center;
}


.cu-form-item label.focused {
    color: var(--primary-color);
    border-bottom: 2px solid var(--primary-color);
}

.cu-form-item.text label.focused {
    color: var(--primary-color);
    border-bottom: 2px solid var(--primary-color);
}

.cu-form-item input,
.cu-form-item textarea {
    border: none;
    border-bottom: 1px solid #8e8e8e;
    width: 100%;
    font-size: 16px;
    color: var(--secondary2-color);
    font-weight: 500;
    height: 100%;
    padding: 10px;
}

.cu-form-item input:focus,
.cu-form-item textarea:focus {
    outline: none;
    border-bottom: 2px solid var(--primary-color);
}


.cu-form-item textarea {
    height: 100%;
    padding: 25px 10px;
}

.cu-form-item button {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    font-size: 18px;
    font-weight: 600;
    padding: 10px 10px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    border: none;
    border-radius: 3px;
    transition: 0.3s;
    cursor: pointer;
}

.cu-form-item button:hover {
    background-color: var(--primary2-color);
}

.cu-horizontal {

    background-color: var(--primary-color);
    width: 70%;
    border-radius: 50px;
    border-bottom: 1.5px solid var(--primary-color);
}

.cu-info-container ul {
    padding-top: 15px;
}

@media(max-width: 800px) {
    .contact-us-main-content-container {
        flex-direction: column;
    }

    .cu-form-container {
        width: 100%;
        margin: auto;
        margin-top: 50px;
    }

    .cu-info-container {
        width: 90%;
        margin: auto;
    }

    .cu-info-container h1,
    .cu-info-item {
        width: 100%;
    }

    .contact-us-main-content-container {
        padding-top: 50px;
    }

    .cu-form-item input,
    .cu-form-item textarea {
        border: none;
        border-bottom: 1px solid #8e8e8e;
        width: 100%;
        font-size: 14px;
        color: var(--secondary2-color);
        font-weight: 500;
        height: 100%;
        padding: 10px;
    }
}