* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #f2a41d;
  --primary2-color: #00437d;
  --secondary-color: #ffffff;
  --secondary2-color: #4a4b4c;
}